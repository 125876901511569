import * as React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGamepad, faDesktop } from '@fortawesome/free-solid-svg-icons'
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { Button, Container, Typography } from '@mui/material';

import colors from "assets/theme/base/colors";
const { primary } = colors;
const StyledOutlinedButton = styled(Button)(() => ({
    borderColor: primary.focus,
    color: primary.focus,
    padding: '16px 22px',
}));

export default function CallToAction() {
    return (
        <Box sx={{ backgroundColor: '#6772e5' }}>
            <Container>
                <Stack spacing={3} direction={{ xs: "column", md: "row" }} textAlign={{ xs: "center", md: "left" }} justifyContent="space-between" alignItems="center">
                    <Box sx={{ color: '#fff' }}>
                        <Typography sx={{ fontSize: '30px' }}>You might want to save my Resume</Typography>
                        <Typography>Then you can look at it later</Typography>
                    </Box>
                    <Box textAlign="right">
                        <StyledOutlinedButton href="https://drive.google.com/uc?export=download&amp;id=1hUXNHzlyRMDlcm1IJUE4v7gKctCFGoG6" variant="outlined" size="large">Download Resume</StyledOutlinedButton>
                    </Box>
                </Stack>
            </Container>
        </Box >
    )
}