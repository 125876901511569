// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
import GitHubIcon from "@mui/icons-material/GitHub";

// Pages
import Portfolio from "pages/Portfolio";
import Home from "pages/Home";

const routes = [
  {
    name: "home",
    route: "/",
    component: <Home />,
    key: "Home"
  },
  {
    name: "portfolio",
    route: "/portfolio",
    component: <Portfolio />,
    key: "Portfolio"

  },
  {
    name: "Download Resume",
    description: "",
    href: "https://drive.google.com/uc?export=download&amp;id=1hUXNHzlyRMDlcm1IJUE4v7gKctCFGoG6",
  },
];

export default routes;
