// General components
import ElevateAppBar from "components/ElevateAppBar";
import Footer from "components/Footer";


// Home page sections
import Hero from "./sections/Hero";
import Features from "./sections/Features";
import PortfolioHighlights from "./sections/PortfolioHighlights";
import Testimonials from "pages/Home/sections/Testimonials";
import Skills from "./sections/Skills";
import CallToAction from "./sections/CallToAction";
import Contact from "./sections/Contact";

// Images
// import bgImage from "assets/images/bg-Home.jpg";

function Home() {
  return (
    <>
      <ElevateAppBar />
      <Hero />
      <Features />
      <PortfolioHighlights />
      <Testimonials />
      <Skills />
      <CallToAction />
      <Contact />
      <Footer />
    </>
  );
}

export default Home;
