import * as React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGamepad, faDesktop } from '@fortawesome/free-solid-svg-icons'
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { Container, Divider, Stack, Typography } from '@mui/material';
import Icon from '@mui/material/Icon';


function Feature(props) {
    return (
        <Box align="center">
            <Stack container spacing={3}>
                <FontAwesomeIcon icon={props.icon} size="2x" />
                <Typography variant='h4'>{props.heading}</Typography>
                <Typography variant='body1' color='text.secondary'>{props.content}</Typography>
            </Stack>
        </Box>
    )
}

export default function Features() {
    return (
        <Box sx={{ backgroundColor: '#f6f9fc' }}>
            <Container >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <Feature icon={faGamepad} heading="Game Development" content="Published and award winning game developer, with a Masters degree in game development." />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Feature icon={faDesktop} heading="Programming" content="Good understanding of programming and scripting. Comfortable with object oriented programming, real code and visual programming." />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Feature icon={faImage} heading="Graphic Design" content="Experience with photography and lighting, as well as photo manipulation, retouching and colour correction." />
                    </Grid>
                </Grid>
            </Container>
            <Divider />
        </Box >
    )
}