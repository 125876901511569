import * as React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faHouse, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Button, Container, Divider, Typography } from '@mui/material';

import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import HeadingWithLine from 'components/HeadingWithLine';

const containerStyle = {
    width: 'auto',
    height: '200px',
    // .googlemap.gmnoprint {
    //     display: none;
    // }
};

const center = {
    lat: 55.450732,
    lng: 12.038968
};

function Contact() {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAgErtxbSxQFOF-klr432afDD5elXIqYUs"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return (
        <Box sx={{ backgroundColor: '#19232d', color: '#6b7c93' }}>
            <Container>
                <HeadingWithLine headingColor='white'>Contact</HeadingWithLine>
                <Grid container spacing={4} marginBottom='50px'>
                    <Grid item xs={12} sm={4}>
                        <Box textAlign='center'>
                            <FontAwesomeIcon icon={faPhone} size='2x' />
                            <Typography marginTop={'25px'}>+45 61780925</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box textAlign='center'>
                            <FontAwesomeIcon icon={faHouse} size='2x' />
                            <Typography marginTop={'25px'}>Ejetoften 51<br />4632 Bjæverskov, Denmark</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box textAlign='center'>
                            <FontAwesomeIcon icon={faEnvelope} size='2x' />
                            <Typography marginTop={'25px'} >mikaelpeterolsen@gmail.com</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{
                    '.gmnoprint': {
                        display: 'none'
                    },
                    'a > div > img': {
                        display: 'none !important'
                    }
                }}>
                    {isLoaded ? (
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={12}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            options={{
                                zoomControl: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                                rotateControl: false,
                                scaleControl: false,
                                fullscreenControl: false,
                                disableDefaultUI: true,
                                minZoom: 12 - 7,
                                maxZoom: 12 + 4,
                                zoom: 12,
                                restriction: {
                                    latLngBounds: {
                                        north: 57.77,
                                        south: 54.55,
                                        east: 15.24,
                                        west: 7.97,
                                    },
                                },
                            }}
                        />
                    ) : <Box sx={containerStyle} color={'white'}></Box>}
                </Box>
            </Container >
        </Box >
    )
}

export default React.memo(Contact)