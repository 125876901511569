import React, { useState, useEffect } from 'react';
import { Button, Stack } from '@mui/material';
import { styled } from "@mui/system"

import styles from './Hero.module.css'

import colors from "assets/theme/base/colors";

import Box from '@mui/material/Box';
// import boxShadow from 'assets/theme/functions/boxShadow';
import { Container, Typography } from '@mui/material';

const { primary } = colors;
const StyledOutlinedButton = styled(Button)(() => ({
    borderColor: primary.focus,
    color: primary.focus,
}));

export default function Hero() {
    const [scrollY, setScrollY] = useState(0);
    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <Box sx={{ boxShadow: 4, backgroundPosition: `50% ${Math.round(scrollY * -0.3)}px` }} className={styles.section}>
            <Box className={styles.blacklayer} />
            <Container>
                <Stack container spacing={2} sx={{ position: 'relative' }}>
                    <Typography variant='h1' color='#ffffff'>Cloud Specialist // DevOps Engineer // Programmer</Typography>
                    <Typography variant='subtitle1' color='#ffffff'>I am a Game developer. I am a Programmer. I am a Photographer. I am a Graphical Artist. I am a Father. I like to use my talents.
                        <br />
                        <br />I can help you with your next project.</Typography>
                    <Stack spacing={4} direction={{ xs: "column", md: "row" }} justifyContent="center" alignItems="center">
                        <StyledOutlinedButton href="/portfoliomaster" variant="outlined" size="large">View Portfolio</StyledOutlinedButton>
                        <Button href="https://drive.google.com/uc?export=download&amp;id=1hUXNHzlyRMDlcm1IJUE4v7gKctCFGoG6" variant="contained" size="large" color="primary">Download Resume</Button>
                    </Stack>
                </Stack>
            </Container>
        </Box >
    )
}