import * as React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGamepad, faDesktop } from '@fortawesome/free-solid-svg-icons'
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { Container, Divider, Stack, Typography } from '@mui/material';
import HeadingWithLine from 'components/HeadingWithLine';


function Skill(props) {
    return (
        <Box>
            <Box alignItems='center' display='flex' paddingBottom={'10px'}>
                <Box
                    component="img" sx={{
                        height: 36,
                        maxWidth: '100%',
                        marginRight: '15px',
                    }} src={props.img} alt="" loading="lazy" />
                <Typography variant='h4'>{props.heading}</Typography>
            </Box>
            <Typography variant='body1' color='text.secondary'>{props.content}</Typography>
        </Box >
    )
}

export default function Skills() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Container>
                <HeadingWithLine>Top Skills</HeadingWithLine>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <Skill img="\assets\images\Logos-Adobe-Photoshop-Copyrighted-icon.png" heading="Adobe Photoshop" content="Self-taught pixel artist, who specialises in photo-bashing, image retouching and colour correction." />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Skill img="\assets\images\CryengineLogo.png" heading="CryEngine" content="More than 2 year professional experience with CryEngine. Excellent knowledge of Flowgraph visual scripting and UI setup." />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Skill img="\assets\images\Logos-Adobe-Flash-Copyrighted-icon.png" heading="Adobe Flash" content="Experience implementing UI using Flash and ActionScript. Was a teaching assistant at my university in a programming course using Flash." />
                    </Grid>
                </Grid>
            </Container>
        </Box >
        // <Grid class="fatures">
        //     <div class="row multi-columns-row">
        //         <div class="col-sm-4 col-md-4 col-lg-4"><div class="feature">
        //             <i class="fa fas fa-gamepad"></i><h3>Game Development</h3>
        //             <p>Published and award winning game developer, with a Masters degree in game development.</p>
        //         </div></div>
        //         <div class="col-sm-4 col-md-4 col-lg-4"><div class="feature">
        //             <i class="fa fas fa-desktop"></i><h3>Programming</h3>
        //             <p>Good understanding of programming and scripting. Comfortable with object oriented programming, real code and visual programming.</p>
        //         </div></div>
        //         <div class="col-sm-4 col-md-4 col-lg-4"><div class="feature">
        //             <i class="fa fas fa-image"></i><h3>Graphic Design</h3>
        //             <p>Experience with photography and lighting, as well as photo manipulation, retouching and colour correction.</p>
        //         </div></div>            		</div>
        // </Grid>
    )
}