import { Box, Typography } from '@mui/material';
import * as React from 'react';

const logoStyle = {
    width: '140px',
    height: 'auto',
};


export default function HeadingWithLine({ headingColor = 'text.primary', children }) {
    return (
        <>
            <Typography variant='h3' align="center" color={headingColor}>{children}</Typography>
            <Box backgroundColor="secondary.main" sx={{
                position: 'relative',
                height: '3px',
                width: '80px',
                left: '50%',
                marginLeft: '-40px',
                marginBottom: '30px',
                top: '-20px',
            }} />
        </>
    );
}