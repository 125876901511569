import * as React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGamepad, faDesktop } from '@fortawesome/free-solid-svg-icons'
import { faImage } from "@fortawesome/free-regular-svg-icons";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import { Container, Divider, Stack, Typography } from '@mui/material';
import HeadingWithLine from 'components/HeadingWithLine';

function PortfolioHighlight(props) {
    return (
        <Box>
            <Link underline="none" href={props.href}>
                <Box
                    component="img" sx={{
                        border: 0,
                        height: 'auto',
                        maxWidth: '100%',
                        verticalAlign: 'middle',
                        marginBottom: '25px',
                    }} src={props.img} alt="" loading="lazy" />
                <Typography variant='h2' sx={{
                    color: '#31ddb7',
                    fontWeight: 700,
                    fontSize: '30px',
                    marginBottom: '15px',
                }}>{props.title}</Typography>
            </Link>
            <Typography color={'#6b7c93'}>{props.children}</Typography>
        </Box>


    )
}

export default function PortfolioHighlights() {
    return (
        <Box >
            <Container>
                <HeadingWithLine>Portfolio Highlights</HeadingWithLine>
                <Grid container spacing={5} marginBottom={'20px'}>
                    <Grid item xs={6} sm={4}>
                        <PortfolioHighlight href="portfolio/aporia-beyond-the-valley/" img="/assets/images/header.jpg" title="Aporia - Beyond the Valley">Aporia: Beyond The Valley&nbsp;is an&nbsp;award winning single-player first person mystery adventure game set a detailed open world accompanied by a&nbsp;breathtaking soundtrack. Aporia brings a unique approach to […]</PortfolioHighlight>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <PortfolioHighlight href="/portfolio/ui-war-of-rights/" img="/assets/images/20190103151822_1.jpg" title="UI - War of Rights">Was in charge of updating the current ingame and menu UI to 4K. The UI was created in Flash ActionScript 2 with Scaleform elements. Everything was overhauled, new features was added, such as new ingame popup notices, dynamic UI to fit widescreen resolution, and dynamic menu elements.</PortfolioHighlight>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <PortfolioHighlight href="/portfolio/photobashing-lundbeck/" img="/assets/images/lundbeck_featureImg.jpg" title="Photobashing - Lundbeck">Design work for H. Lundbeck A/S</PortfolioHighlight>
                    </Grid>
                </Grid>
                <Box align='center'>
                    <Button href="https://drive.google.com/uc?export=download&amp;id=1hUXNHzlyRMDlcm1IJUE4v7gKctCFGoG6" variant="outlined">View Portfolio →</Button>
                </Box>
            </Container>
        </Box >
    )
}