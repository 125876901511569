// General components
import ElevateAppBar from "components/ElevateAppBar";
import Footer from "components/Footer";


// Portfolio page sections
import PortfolioHighlights from "./sections/PortfolioHighlights";

function Portfolio() {
  return (
    <>
      <PortfolioHighlights />
    </>
  );
}

export default Portfolio;
