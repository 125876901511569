import { Box, Container, Divider, Grid, IconButton, List, ListItem, Stack, Typography } from '@mui/material';
import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedin, faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons";

const logoStyle = {
    width: '140px',
    height: 'auto',
};


export default function Footer() {
    return (
        <Box sx={{ backgroundColor: '#121a21', color: '#6b7c93' }}>
            <Container sx={{
                paddingTop: '25px !important',
                paddingBottom: '25px !important',
            }}>
                <Stack direction={'row'} alignItems={'center'}>
                    <Typography >{'© '}{new Date().getFullYear()} Mikael Peter Olsen | All Rights Reserved</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton href="http://www.facebook.com/mikaelpeterolsen"><FontAwesomeIcon icon={faFacebook} color='#31ddb7' size='xs' /></IconButton>
                    {/* <IconButton href="http://twitter.com/mikepapaoscar"><FontAwesomeIcon icon={faTwitter} color='#31ddb7' size='xs' /></IconButton> */}
                    <IconButton href="http://www.linkedin.com/in/mikaelpeterolsen"><FontAwesomeIcon icon={faLinkedinIn} color='#31ddb7' size='xs' /></IconButton>

                </Stack>
            </Container>
        </Box>
    );
}