/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React Base Styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

// Material Kit 2 React Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

// const { white, transparent, light, info, secondary, primary, text, grey } = colors;
const { secondary, primary } = colors;
const { size } = typography;

export default {
  base: {
    // minHeight: pxToRem(40),
    // color: grey[100],
    // borderColor: grey[200],
    // padding: `${pxToRem(10)} ${pxToRem(24)}`,

    border: `solid ${pxToRem(2)}`,
    // borderStyle: "solid",

    "&:hover": {
      // backgroundColor: grey[200],
      // borderColor: grey[200],
      borderWidth: pxToRem(2),
      // color: grey[800],
      // opacity: 0.75,
      // backgroundColor: transparent.main,
    },

    // "&:focus:not(:hover)": {
    //   backgroundColor: primary.focus,
    //   borderColor: primary.focus,
    //   color: primary.focusContrastText,
    // },

    // "& .material-icon, .material-icons-round, svg": {
    //   fontSize: `${pxToRem(16)} !important`,
    // },
  },

  primary: {
    borderColor: primary.main,

    "&:hover": {
      backgroundColor: primary.focus,
      borderColor: primary.focus,
      color: primary.focusContrastText,
    },

    "&:focus": {
      color: primary.dark,
      borderColor: primary.dark,
    },
  },

  secondary: {
    // backgroundColor: transparent.main,
    borderColor: secondary.main,

    "&:hover": {
      backgroundColor: primary.focus,
    },
  },
};
