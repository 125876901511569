import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Stack, useMediaQuery } from '@mui/material';
// import { useTheme } from '@mui/system';
import Grid from '@mui/material/Grid';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

const userTestimonials = [
    {
        image: <img alt="Spilprisen" src="assets/images/SP18_FB_profile_01_small_0.jpg" />,
        imageUrl: "assets/images/SP18_FB_profile_01_small_0.jpg",
        name: 'Spilprisen 2018',
        testimonial: 'Special Mention',
        testimonial2: 'Aporia - Beyond the Valley',
    },
    {
        image: <img alt="CryEngine" src="assets/images/3b1e9c_685d1966bab84364ba7dfbc1a48059ac_mv2.jpg" />,
        imageUrl: "assets/images/3b1e9c_685d1966bab84364ba7dfbc1a48059ac_mv2.jpg",
        name: 'CryEngine',
        testimonial: 'Indie Game of the Year 2017',
        testimonial2: 'Aporia - Beyond the Valley',
    }
];

// const whiteLogos = [
//     'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg',
//     'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
//     'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
//     'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
//     'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
//     'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
// ];

// const darkLogos = [
//     'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg',
//     'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg',
//     'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg',
//     'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg',
//     'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg',
//     'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg',
// ];

// const logoStyle = {
//     width: '64px',
//     opacity: 0.3,
// };

export default function Testimonials() {
    // const theme = useTheme();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const columns = isSmallScreen ? 1 : 3;
    // const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

    return (

        <Box sx={{ backgroundColor: '#19232d' }}>
            <Container>
                <Typography marginTop={'40px'} variant='h3' align="center" color='white'>Awards</Typography>

                <Grid container spacing={"40px"}>
                    {userTestimonials.map((testimonial, index) => (
                        <Grid item md={6} xs={12}>
                            <Stack>
                                <Card key={index}>
                                    <CardContent>
                                        <FontAwesomeIcon icon={faQuoteLeft} size='3x' color='#31ddb7' />
                                        <Typography paddingTop={'10px'} color={'#6b7c93'}>
                                            <b>{testimonial.testimonial}</b>
                                            <br />
                                            {testimonial.testimonial2}
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <Box sx={{
                                    width: 0,
                                    borderStyle: 'solid',
                                    borderWidth: '15px 15px 0 15px',
                                    borderColor: '#ffffff transparent transparent transparent',
                                    marginLeft: '40px',
                                    paddingBottom: '12px'
                                }} />
                                <Box alignItems='center' display='flex'>
                                    <Box
                                        component="img" sx={{
                                            height: 55,
                                            maxWidth: '100%',
                                            borderRadius: 55 / 2,
                                            marginRight: '15px',
                                        }} src={testimonial.imageUrl} alt="" loading="lazy" />
                                    <Typography color={'#6b7c93'}>
                                        {testimonial.name}
                                    </Typography>
                                </Box>

                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}